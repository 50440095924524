* {
  font-family: "Inter";
  font-size: 16.88px;
  box-sizing: border-box;
}

.rc {
  font-family: "Roboto Condensed";
  font-size: 27px;
  color: black;
}

body {
  margin: 0;
}

button {
  background-color: black;
  border: unset;
  color: white;
  height: 40px;
  padding: 0px 25px;
  border-radius: 100px;
  font-size: 15.25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  box-sizing: border-box;
}

.cbr {
  transition: all 200ms ease-in-out;
}

button:hover .cbr {
  transform: translateX(10px);
}

.w-screen {
  width: 100vw;
}

.h-screen {
  height: 100vh;
}

.defPad {
  padding: 0 60px;
}

.horiFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.logobg {
  background-image: url(./images/borderlogo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.t1 {
  font-size: 67.92px;
  text-align: center;
}

.t2 {
  width: 60%;
  padding-bottom: 80px;
  text-align: center;
}

.bh {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 80px;
}

.bs {
  flex: 1;
}

.t2h {
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wt {
  font-size: 44.81px;
}

.lb {
  height: 300px;
}

.f1 {
  font-size: 37.92px;
}

.ft1 {
  margin-bottom: 14px;
  color: white;
  font-size: 24px;
}

.ft1-2 {
  color: black;
  font-size: 24px;
}

.fi {
  box-sizing: border-box;
  outline: none;
  background-color: #8000ff;
  border: none;
  padding-bottom: 14px;
  border-bottom: 1px solid #ffffffc7;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.fi::placeholder {
  color: #ffffffc7;
  font-weight: bold;
  font-size: 12px;
}

.ft {
  box-sizing: border-box;
  resize: vertical;
  outline: none;
  min-height: 100px;
  background-color: #8000ff;
  border: none;
  padding-bottom: 14px;
  border-bottom: 1px solid #ffffffc7;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.ft::placeholder {
  color: #ffffffc7;
  font-weight: bold;
  font-size: 12px;
}

.fb {
  border-radius: 3px;
  background-color: white;
  color: #8000ff;
  font-size: 14px;
  width: max-content;
  font-weight: bolder;
  transition: all 100ms ease-in-out;
}

.fn21 {
  padding: 180px 0px;
}

.fb:hover {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.296),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.fb:active {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0), 0 3px 6px -4px rgba(0, 0, 0, 0),
    0 9px 28px 8px rgba(0, 0, 0, 0);
  transform: translateY(0px);
}

.fbox {
  width: 360px;
}

.shd {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.296),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff95;
}

.fbg {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/footerbg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.25;
}

.fclt {
  text-decoration: unset;
  text-decoration-line: unset;
  color: black;
}

a:hover div svg {
  scale: 120%;
  transition: all 200ms ease;
}

.ant-message-custom-content span,
.ant-message-success span {
  font-size: 14px;
}

.fdm {
  flex-direction: row;
}

.ctxt {
  color: #797979;
  font-size: 14px;
}

.b22 {
  background-color: unset;
  border-radius: unset;
  color: black;
  font-weight: bold;
  transition: all 100ms ease-in-out;
  margin: 0px 30px;
  padding: 0 10px;
}

.b22:hover {
  scale: 105%;
}

@media (max-width: 920px) {
  .t1 {
    font-size: 27.92px;
    text-align: start;
  }
  .t2 {
    width: 100%;
    padding-bottom: 20px;
    text-align: start;
  }
  .bh {
    flex-direction: column;
    margin-top: "0px";
  }
  .bs {
    flex: unset;
    width: 100%;
  }
  .t2h {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .wt {
    font-size: 34.81px;
    text-align: start;
    width: 100%;
    padding-left: 40px;
  }
  .lb {
    height: 200px;
  }
  .foologo {
    display: none;
  }
  .f1 {
    font-size: 27.92px;
  }
  .fbox {
    width: 100%;
    margin: 0px;
  }
  .fdm {
    flex-direction: column;
  }
  .fn21 {
    padding: 0px 0px;
  }
  .defPad {
    padding: 0 20px;
  }
}

/* cursor starts */

.cur-holder {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
  position: fixed;
  opacity: 50%;
  z-index: 999;
}

.cur-position {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-box-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  animation: rotate1 2s linear infinite;
  transition: all 500ms ease-in-out;
}

.planet-1 {
  height: 12px;
  width: 12px;
  background-color: black;
  border-radius: 100%;
  transform: translateX(-6px);
}

.c-box-2 {
  position: absolute;
  width: 220px;
  height: 220px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  animation: rotate1 3s linear infinite;
  transition: all 500ms ease-in-out;
}

.c-box-2-2 {
  position: absolute;
  width: 220px;
  height: 220px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  animation: rotate1 1.5s linear reverse infinite;
  transition: all 500ms ease-in-out;
}

.planet-2 {
  height: 12px;
  width: 12px;
  background-color: black;
  border-radius: 100%;
  transform: translateX(-6px);
}

.planet-3 {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 100%;
  transform: translateX(-5px);
}

.c-box-3 {
  position: absolute;
  width: 280px;
  height: 280px;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  animation: rotate1 2s linear reverse infinite;
  transition: all 500ms ease-in-out;
}

.planet-4 {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 100%;
  transform: translateX(-4px);
}

@keyframes rotate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
